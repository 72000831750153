





















































import { OtherModule } from "@/store/other";
import { Vue, Component } from "vue-property-decorator";

@Component
export default class extends Vue {
  get pay_type(): string {
    return this.$route.params.type;
  }

  go_1() {
    this.$router.replace(this.$route.query.go_1 as string);
  }

  go_2() {
    this.$router.replace(this.$route.query.go_2 as string);
  }


  get fail_msg() {
    return (this.$route.query.fail_msg as string | undefined) || `실패안내`;
  }

  get somePrice() {
    return sessionStorage.getItem("somePrice");
  }

  get is_mobile(): boolean {
    return OtherModule.is_mobile;
  }
}
